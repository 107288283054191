















































import { computed, defineComponent, PropType, useRoute } from '@nuxtjs/composition-api';
import { NavigationItem } from '@/configs';
import ApplicationBarToggleButton from '@/components/layout/applicationbar-toggle-button.vue';
import NavigationSubButton from '@/components/layout/navigation-sub-button.vue';
import { appStore, userStore } from '@/store';
import { testBar } from '@/utils';
import { isSubItemSelected } from '@/compositions/sidenavi';

export default defineComponent({
  name: 'SpSettingDrawer',
  components: { NavigationSubButton, ApplicationBarToggleButton },
  props: {
    items: {
      type: Array as PropType<NavigationItem[]>,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const currentPath = computed(() => route.value.fullPath);
    const adjustment = testBar() && !appStore.appConfig.is_yappli_client
      ? 'margin-top: 12px'
      : '';
    return { currentPath, userStore, adjustment, isSubItemSelected };
  }
});
