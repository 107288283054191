




























































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'NavigationSubButton',
  props: {
    iconName: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    selected: {
      type: Boolean,
      default: false
    },
    fontBold: {
      type: Boolean,
      default: false
    },
    badge: {
      type: Boolean,
      default: false
    },
    fontSmall: {
      type: Boolean,
      default: false
    },
    badgeContent: {
      type: [String, Number],
      default: undefined
    }
  }
});
