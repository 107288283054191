/* eslint-disable quote-props */
import companyListTableColumnDef from './companyListTableColumnDef';

export default {
  '企業登録詳細画面_却下tooltipメッセージ': `
    <div>
      申請を却下する場合はこちらをクリックしてください。却下された旨は企業に通知されません。
    </div>
    <div>
      ※承認前の企業のみボタンが表示されます
    </div>
  `,
  '企業一覧画面_tableColumnDef': companyListTableColumnDef
};
