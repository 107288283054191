var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "pt-5", attrs: { justify: "end", "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-right pr-6" }, [
            _c("div", { staticClass: "caption" }, [
              _vm._v("\n        " + _vm._s(_vm.buildVersion) + "\n        "),
              _vm.$environment !== "PRODUCTION" && _vm.yappliRegisteredId
                ? _c("span", { staticClass: "px-2 text-right" }, [
                    _vm._v(
                      "\n          Yappli.sendRegisteredId = " +
                        _vm._s(_vm.yappliRegisteredId) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        {
          staticClass: "mt-2",
          attrs: { align: "center", dark: "", padless: "" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { "offset-md": "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "text-transform": "none" },
                      attrs: {
                        color: "white",
                        small: "",
                        text: "",
                        href: "/assets/document/ba_rule.pdf",
                      },
                    },
                    [_vm._v("\n          Big Advance 運営規約\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "text-transform": "none" },
                      attrs: {
                        color: "white",
                        small: "",
                        text: "",
                        href: "/assets/document/ba_service_specification.pdf",
                      },
                    },
                    [_vm._v("\n          Big Advance サービス仕様書\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", small: "", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showContact = true
                        },
                      },
                    },
                    [_vm._v("\n          機能要望投稿フォーム\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        nuxt: "",
                        to: "/admin/partnerform",
                        small: "",
                        text: "",
                      },
                    },
                    [
                      _vm._v(
                        "\n          パートナー企業事前照会フォーム\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700", persistent: "" },
          model: {
            value: _vm.showContact,
            callback: function ($$v) {
              _vm.showContact = $$v
            },
            expression: "showContact",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "formContactRef",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validContact.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "px-3",
                      attrs: { dark: "", color: "primary", dense: "" },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("機能要望投稿フォーム")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.showContact = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        "\n          改善要望をお気軽にご連絡ください\n          "
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pt-5" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "内容",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.isRequired],
                                },
                                model: {
                                  value: _vm.formContact.body,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formContact, "body", $$v)
                                  },
                                  expression: "formContact.body",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-3" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "second" },
                          on: {
                            click: function ($event) {
                              _vm.showContact = false
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-close")]),
                          _vm._v("\n            閉じる\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { attrs: { color: "primary", type: "submit" } },
                        [
                          _c("v-icon", [_vm._v("mdi-send")]),
                          _vm._v("\n            送信\n          "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }