import request from '@/utils/api';
import { userStore, appStore } from '@/store';

// マッチングニーズデータ取得
export const getAdvertisementTypes = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/ba_ca_types',
    method: 'get'
  });
};

// 業種マスタデータ取得
export const getCategoryTypes = (): any =>
  request({
    url: appStore.appConfig.bamanager_url + '/api/v1/master/cbt',
    method: 'get'
  });

// 補助金・助成金カテゴリーマスタデータ取得
export const getSubsidyClass = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/subsidy_classes',
    method: 'get'
  });
};

// 退会理由データ取得
export const getUnsubscribeReasonType = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/unsubscribe_reasons',
    method: 'get'
  });
};

// アクション種別(マッチング)
export const getAnalyseAction = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/analyse_action',
    params: { category: 'matching' },
    method: 'get'
  });
};

// BA銀行取得
export const getBABanks = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  if (!hasBankAdminPermissions()) {
    return null;
  }
  return request({
    url: '/api/v1/master/ba_banks',
    method: 'get'
  });
};

// BA銀行支店取得
export const getBABankBranches = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  if (!hasBankAdminPermissions()) {
    return null;
  }
  return request({
    url: '/api/v1/master/ba_bank_branches',
    method: 'get'
  });
};

// 銀行支店取得
export const getBankBranches = (): any =>
  request({
    url: '/api/v1/master/bank_branches',
    method: 'get'
  });

// ユーザー種別取得
export const getUserTypes = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/user_types',
    method: 'get'
  });
};

// セミナーステータス取得
export const getSeminarStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/seminar_statuses',
    method: 'get'
  });
};

// 興味がある機能取得
export const getInterestingFeatrues = (): any =>
  request({
    url: '/api/v1/master/interesting_features',
    method: 'get'
  });

// ニュースカテゴリーズ
export const getNewsCategories = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/news_categories',
    method: 'get'
  });
};

// 企業登録申請状態取得
export const getCompanyregisterStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  if (!hasBankAdminPermissions()) {
    return null;
  }
  return request({
    url: '/api/v1/master/cr_statuses',
    method: 'get'
  });
};

// アクティビティタイプ取得
export const getActivityTypes = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/activity_types'
  });
};

// 企業登録申請状態取得
export const getCompanyTypes = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  if (!hasBankAdminPermissions()) {
    return null;
  }
  return request({
    url: '/api/v1/master/company_types'
  });
};

// ユーザ書類カテゴリ
export const getDocumentCategories = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/document_categories',
    method: 'get'
  });
};

// 商談依頼ステータス
export const getCmhStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/cmh_statuses',
    method: 'get'
  });
};

// 課金ステータス
export const getChargeStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/charge_statuses',
    method: 'get'
  });
};

// 企業ステータス
export const getCompanyStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/company_statuses',
    method: 'get'
  });
};

// カテゴリ名
export const getCsoCategories = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/cso_categories',
    method: 'get'
  });
};

// 福利厚生提供サービスステータス
export const getCsoStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/cso_statuses',
    method: 'get'
  });
};

// 公開状況
export const getCsoPublishStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/cso_publish_statuses',
    method: 'get'
  });
};

// チャットルームタイプデータ取得
export const getChatRoomTypes = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/chat_room_types',
    method: 'get'
  });
};

// イノベーション履歴ステータスデータ取得
export const getCompanyInnovationHistoriesStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  if (!hasBankAdminPermissions()) {
    return null;
  }
  return request({
    url: '/api/v1/master/company_innovation_histories_statuses',
    method: 'get'
  });
};

// 商談依頼ステータス
export const getCmhRequestTypes = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }

  return request({
    url: '/api/v1/master/cmh_request_types',
    method: 'get'
  });
};

// パートナー情報ステータス取得
export const getPiStatuses = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/master/partner_information_statuses',
    method: 'get'
  });
};

// API側で金融機関ユーザーのみアクセスを許可している機能はAPIにリクエストを投げさせない
const hasBankAdminPermissions = (): boolean => {
  return (
    userStore.loginUser.user_type === 'system_admin' ||
    userStore.loginUser.user_type === 'bank_admin' ||
    userStore.loginUser.user_type === 'branch_admin' ||
    userStore.loginUser.user_type === 'branch_user'
  );
};

// イベントコード取得
export const getEventCodes = (): any => {
  if (!hasBankAdminPermissions()) {
    return null;
  }
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }

  return request({
    url: '/api/v1/master/event_codes',
    method: 'get'
  });
};

// きっかけ
export const getRegisterReason = (): any => {
  return request({
    url: '/api/v1/companyregister/reasons',
    method: 'get'
  });
};

// 商談関連チャット差込フィールド
export const getUserCfpVariables = (): any => {
  return request({
    url: '/api/v1/user/cfp_variables',
    method: 'get'
  });
};

// Pendoのaccount情報取得
export const getPendoAccount = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: `/api/v1/company/pendo?company_unique_code=${userStore.loginUser.company_unique_code}`,
    method: 'get'
  });
};

// Pendoの金融機関ユーザーaccount情報取得
export const getPendoBankerAccount = (): any => {
  if (!hasBankAdminPermissions()) {
    return null;
  }
  if (
    !localStorage.getItem('auth._token.bamidp') ||
      localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/bank/pendo',
    method: 'get'
  });
};

// ログインユーザーの企業情報
export const getLoginCompany = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BAM_BASE_URL + `/api/v1/ba/company/search?company_unique_code=${userStore.loginUser.company_unique_code}`,
    method: 'get'
  });
};

// Forumカテゴリ取得
// ログイン前は取得しない
export const getForumCategory = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BACOM_URL + '/forum/api/v1/master/categories',
    method: 'get'
  });
};
