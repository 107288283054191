var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ApplicationBar"),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticStyle: { height: "50vh", "padding-top": "80px" },
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { flat: "", tile: "" } },
                [
                  _c("v-card-text", [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        { staticClass: "done-message" },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "\n                    display-1\n                    font-weight-black\n                    grey--text\n                    text--lighten-1\n                    py-5\n                  ",
                              },
                              [
                                _vm.error.statusCode === 400
                                  ? [
                                      _vm._v(
                                        "\n                    不正なリクエストです\n                  "
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.error.statusCode === 422
                                  ? [
                                      _vm.error.message
                                        ? _c("div", [
                                            _c("div", {
                                              staticStyle: {
                                                "font-size": "24px",
                                                color: "black",
                                                "font-weight": "normal",
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.error.message
                                                ),
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.error.statusCode === 401
                                  ? [
                                      _vm._v(
                                        "\n                    ログインが必要です\n                  "
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.error.statusCode === 403
                                  ? void 0
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.error.statusCode === 404
                                  ? [
                                      _vm._v(
                                        "\n                    404 Not Found\n                  "
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.error.statusCode === 500
                                  ? [
                                      _vm._v(
                                        "\n                    System Error\n                  "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.error.statusCode !== 422
                            ? _c(
                                "div",
                                [
                                  _vm.error.statusCode !== 401
                                    ? [
                                        _vm.error.message
                                          ? _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.error.message) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _vm._v(
                                                  "\n                    エラーが発生しました。\n                  "
                                                ),
                                              ]
                                            ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "py-10",
                                      attrs: { justify: "center" },
                                    },
                                    [
                                      _vm.error.statusCode === 401
                                        ? [
                                            _vm._v(
                                              "\n                    3秒後にログインページに遷移されます。\n                  "
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.error.statusCode !== 401
                                        ? [
                                            _c(
                                              "nuxt-link",
                                              { attrs: { to: "/" } },
                                              [
                                                _vm._v(
                                                  "\n                      トップページに戻る\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }