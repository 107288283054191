var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c("v-app", { attrs: { dark: "" } }, [
        _c(
          "div",
          [
            _c(
              "v-overlay",
              { attrs: { value: _vm.overlay } },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", size: "64" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-main", [_c("nuxt")], 1),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }