import '@mdi/font/css/materialdesignicons.css';
import '@/assets/css/all.min.css';
import '@/assets/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import DatetimePicker from 'vuetify-datetime-picker';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

Vue.use(DatetimePicker);

export default new Vuetify({
  theme: {
    dark: false
  },
  icons: {
    iconfont: 'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  }
});
