// ユーザ情報ストア
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  MutationAction
} from 'vuex-module-decorators';

import { ILoginUser } from '@/types/user';
import { getUserMe, getMasterBaSystemAdmins } from '@/apis/user';

export interface State {
  loginUser: ILoginUser;
  id_token: string;
  id_token_payload: {};
  permittedActions: string[];
  permittedChatActions: string[];
  disableChat: boolean;
  offhourMessage: string;
  isPermittedIpaddress: boolean;
  isManagedUser: boolean;
}

declare module '@nuxt/types' {
  interface Context {
    $user: State
  }
}
/**
 * ユーザ情報をストアへ登録
 *
 * @export
 * @class User
 * @extends {VuexModule}
 * @implements {State}
 */
@Module({ stateFactory: true, namespaced: true, name: 'user' })
export default class User extends VuexModule implements State {
  loginUser: ILoginUser = {
    bb_code: '',
    bb_id: null,
    bb_name: null,
    company_corporate_number: '',
    company_enable_ba: '',
    company_enable_baportal: null,
    company_id: 0,
    company_is_disabled: null,
    company_is_unsubscribed: 0,
    company_logo_file: null,
    company_name: '',
    company_type: '',
    company_unique_code: '',
    user_bb_name: null,
    user_branche_codes: null,
    user_branches: null,
    user_created_at: '',
    user_email: '',
    user_email_alt: null,
    user_first_name: '',
    user_first_name_kana: '',
    user_id: 0,
    user_is_disabled: 0,
    user_is_super_user: null,
    user_last_name: '',
    user_last_name_kana: '',
    user_login_id: '',
    user_name: '',
    user_profile_picture: null,
    user_restricts_companies_under_my_branch: 0,
    user_role: 0,
    user_tel: '',
    user_type: '',
    user_type_group: '',
    user_type_name: '',
    user_unique_code: '',
    user_updated_at: '',
    user_provider: 0,
    login_id_managements: []
  };

  id_token = '';

  id_token_payload = {};

  permittedActions: string[] = [];

  permittedChatActions: string[] = [];

  disableChat = false;
  offhourMessage = '';
  isPermittedIpaddress = false;
  isManagedUser = false;

  @Mutation
  public SET_USER_ME_VALUE(item: ILoginUser): void {
    this.loginUser = item;
  }

  @Mutation
  public SET_MASTER_BA_SYSTEM_ADMINS_VALUE(item :[]): void {
    this.loginUser.login_id_managements = item;
  }

  @Mutation
  public SET_ID_TOKEN(item: string): void {
    this.id_token = item;
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public SET_ID_TOKEN_PAYLOAD(item: any): void {
    this.id_token_payload = item;
  }

  @Mutation
  public SET_PERMITTED_ACTIONS_VALUE(item: string[]): void {
    this.permittedActions = item;
  }

  @Mutation
  public SET_PERMITTED_CHAT_ACTIONS_VALUE(item: string[]): void {
    this.permittedChatActions = item;
  }

  @Mutation
  public SET_DISABLE_CHAT_VALUE(item: boolean): void {
    this.disableChat = item;
  }

  @Mutation
  public SET_OFFHOUR_MESSAGE_VALUE(item: string): void {
    this.offhourMessage = item;
  }

  @Mutation
  public SET_IS_PERMITTED_VALUE(item: boolean): void {
    this.isPermittedIpaddress = item;
  }

  @Mutation
  public SET_IS_MANAGED_USER_VALUE(item: boolean): void {
    this.isManagedUser = item;
  }

  /**
   * ユーザ情報APIを並列で実行しストア登録を行う
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof User
   */
  @Action({ rawError: true })
  async loadUser(): Promise<boolean> {
    const user: any = await getUserMe();
    if (!user || !user.result) {
      return true;
    }

    this.SET_USER_ME_VALUE(user.result.user);
    this.SET_ID_TOKEN(user.result.token_id);
    this.SET_ID_TOKEN_PAYLOAD(user.result.token_id_payload);
    this.SET_PERMITTED_ACTIONS_VALUE(user.result.permitted_actions);
    this.SET_PERMITTED_CHAT_ACTIONS_VALUE(
      user.result.permitted_chat_actions
    );
    this.SET_DISABLE_CHAT_VALUE(user.result.disable_chat);
    this.SET_OFFHOUR_MESSAGE_VALUE(user.result.offhour_message);
    this.SET_IS_PERMITTED_VALUE(user.result.is_permitted_ipaddress);
    this.SET_IS_MANAGED_USER_VALUE(user.result.is_managed_user);

    if (
      this.loginUser.user_type !== 'company_owner' &&
      this.loginUser.user_type !== 'partner_company_owner' &&
      this.loginUser.user_type !== 'company_user' &&
      this.loginUser.user_type !== 'company_employee'
    ) {
      const masterBaSystemAdmins: any = await getMasterBaSystemAdmins();
      if (masterBaSystemAdmins && masterBaSystemAdmins.result) {
        this.SET_MASTER_BA_SYSTEM_ADMINS_VALUE(masterBaSystemAdmins.result.list);
      }
    }

    return true;
  }

  // 1つ以上の状態プロパティを直接割り当てたい場合は使用(ミューテーションの作成は不要)
  // actions + mutation
  @MutationAction({ mutate: ['loginUser'] })
  async setUserConfig(): Promise<any> {
    const data = await getUserMe();
    return {
      loginUser: data.result.config
    };
  }
}
