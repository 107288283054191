var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "navigation-main-button d-flex flex-column justify-center align-center",
      class: { "-thin": !_vm.title },
      on: {
        click: function ($event) {
          return _vm.$emit("handle-click")
        },
      },
    },
    [
      _vm.badge || _vm.badgeContent
        ? _c(
            "v-badge",
            {
              staticClass: "badge",
              attrs: {
                color: "error",
                content: _vm.badgeContent,
                bordered: "",
                small: "",
                dot: _vm.badge,
                "offset-x": _vm.badge && !_vm.badgeContent ? 18 : 20,
                "offset-y": _vm.badge && !_vm.badgeContent ? 10 : 18,
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "button",
                  class: { "-selected": _vm.selected },
                  attrs: {
                    rounded: "",
                    plain: "",
                    elevation: "0",
                    width: _vm.width,
                    height: "30",
                    ripple: false,
                  },
                },
                [
                  _vm._t("icon", function () {
                    return [_c("v-icon", [_vm._v(_vm._s(_vm.iconName))])]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "button",
              class: { "-selected": _vm.selected },
              attrs: {
                rounded: "",
                plain: "",
                elevation: "0",
                width: _vm.width,
                height: "30",
                ripple: false,
              },
            },
            [
              _vm._t("icon", function () {
                return [_c("v-icon", [_vm._v(_vm._s(_vm.iconName))])]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "btntitle mb-0", class: { "-selected": _vm.selected } },
        [
          _vm._t("title", function () {
            return [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }