






















































































import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api';
import { Search } from '@/compositions/base-search';
import { Save } from '@/compositions/base-save';
import { IContactForm, VForm } from '@/types';
import { appStore } from '@/store';
import { useStore } from '@/compositions/store';

export default defineComponent({
  name: 'Footer',
  setup() {
    const { yappliRegisteredId } = useStore();

    const formContact = ref<IContactForm>({
      body: ''
    });

    const showContact = ref<boolean>(false);

    const router = useRouter();

    const { setSaveConfig, saveData, isRequired, valid } = Save();
    const { loadDataWoState } = Search();

    const formContactRef = ref<null | VForm>(null);

    // build_versionを取得する
    const buildVersion = ref<string>('');
    loadDataWoState('/api/v1/buildinfo', {}, 'get').then((data: any) => {
      if (data) {
        buildVersion.value = data.build_version;
      }
    });

    // 機能要望投稿フォームダイアログ入力チェック
    const validContact = (): void => {
      if (formContactRef.value?.validate()) {
        setSaveConfig('/api/v1/dashboard/contact', {
          doToast: true,
          success: '送信しました',
          error: '送信できませんでした'
        });
        saveData({ data: formContact.value }).then(() => {
          showContact.value = false;
          router.push('/admin/dashboard');
        });
      }
    };

    return {
      formContact,
      valid,
      formContactRef,
      validContact,
      isRequired,
      showContact,
      appStore,
      yappliRegisteredId,
      buildVersion
    };
  }
});
