var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "z-index": "900" },
      style: _vm.adjustment,
      attrs: {
        right: "",
        "hide-overlay:true": "",
        app: "",
        permanent: "",
        width: "100%",
      },
    },
    [
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "v-toolbar",
            {
              staticStyle: { "border-bottom": "1px solid #dde3ea" },
              attrs: { flat: "", height: "54" },
            },
            [
              _c("ApplicationBarToggleButton", {
                attrs: { open: "" },
                on: {
                  "handle-click": function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "menu", staticStyle: { "margin-top": "20px" } },
            [
              _vm._l(_vm.items, function (item, index) {
                return _c("NavigationSubButton", {
                  key: index,
                  attrs: {
                    "icon-name": _vm.isSubItemSelected(item, _vm.currentPath)
                      ? item.icon.selected
                      : item.icon.default,
                    title: item.title,
                    selected: _vm.isSubItemSelected(item, _vm.currentPath),
                  },
                  on: {
                    "handle-click": function ($event) {
                      return _vm.$router.push(item.path)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      item.path === "/mypage/setting/invoice"
                        ? {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    src: require("@/assets/img/sidenavi/receipt-text-outline.svg"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._v(" "),
              _c("NavigationSubButton", {
                attrs: { "icon-name": "mdi-logout", title: "ログアウト" },
                on: {
                  "handle-click": function ($event) {
                    return _vm.$router.push("/logout")
                  },
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }