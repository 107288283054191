// ユーザ情報ストア
import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { IAuth } from '@/types/auth';
declare module '@nuxt/types' {
  interface Context {
    $authInfo: IAuth;
  }
}
/**
 * ユーザ情報をストアへ登録
 *
 * @export
 * @class User
 * @extends {VuexModule}
 * @implements {State}
 */
@Module({ stateFactory: true, namespaced: true, name: 'auth' })
export default class Auth extends VuexModule implements IAuth {
  id_token = '';
  id_token_payload = {
    aud: '',
    ba_company_disable_create_shares_case: '',
    ba_company_enable_ba: '',
    ba_company_enable_baportal: '',
    ba_company_name: '',
    ba_company_type: '',
    ba_company_unique_code: '',
    ba_user_bb_code: '',
    ba_user_bb_name: '',
    ba_user_bb_unique_code: '',
    ba_user_email: '',
    ba_user_first_name: '',
    ba_user_first_name_kana: '',
    ba_user_last_name: '',
    ba_user_last_name_kana: '',
    ba_user_name: '',
    ba_user_type: '',
    ba_user_unique_code: '',
    sub: '',
    user_switch_from: ''
  };

  @Mutation
  public SET_AUTH_USER(item: IAuth): void {
    this.id_token = item.id_token;
    this.id_token_payload = item.id_token_payload;
  }
}
