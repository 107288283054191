/**
 * プラン情報
 */
export interface BundlePaymentPlan {
  /** プランID */
  plan_id: number;
  /** プラン名 */
  plan_name: string;
  /** 発行機能の料金 */
  issue_amount: number;
  /** 発行機能の料金（税込） */
  issue_total_amount: number;
  /** 受取機能の料金 */
  receive_amount: number;
  /** 受取機能の料金（税込） */
  receive_total_amount: number;
  /** 合計料金 */
  amount: number;
  /** 合計料金（税込） */
  total_amount: number;
  /** 説明 */
  description: string;
  /** 適用開始日 */
  start_date: string;
  /** 適用終了日 */
  finish_date: string;
  /** 受取最大ファイル数 */
  max_receive_files: number | null;
}

/**
 * キャンペーン情報
 */
export interface BundlePaymentCampaign {
  /** キャンペーンID */
  id: number;
  /** キャンペーン適用月数 */
  campaign_num_of_months: number;
  /** 適用開始日 */
  start_date: string;
  /** 適用終了日 */
  finish_date: string;
  /** 備考 */
  remarks: string;
}

/**
 * 同時加入用のデータ(Store用)
 */
export interface BundlePayment {
  /** 同時加入の表示フラグ */
  isShow: boolean;
  /** プラン情報 */
  plans: BundlePaymentPlan[];
  /** キャンペーン情報 */
  campaign: BundlePaymentCampaign | null;
}

/**
 * ちゃんと請求書プランID
 */
export const BUNDLE_PAYMENT_PLAN_ID = {
  /** 利用なし */
  NONE: 0,
  /** 発行のみプラン */
  ISSUE_PLAN: 1,
  /** 基本プラン */
  ISSUE_RECEIVE_PLAN1: 2,
  /** しっかりプラン */
  ISSUE_RECEIVE_PLAN2: 3,
  /** たっぷりプラン */
  ISSUE_RECEIVE_PLAN3: 4
} as const;

/**
 * キャンペーン情報APIレスポンス
 */
export interface BaPaymentCampaignApiResponse {
  /** ステータス */
  status: 'success' | 'error';
  /** メッセージ */
  message: string;
  /** キャンペーンデータ */
  data: BundlePaymentCampaign;
}

/**
 * プランマスタAPIレスポンス
 */
export interface BaPaymentPlanMasterApiResponse {
  /** ステータス */
  status: 'success' | 'error';
  /** メッセージ */
  message: string;
  /** プランデータ */
  data: BundlePaymentPlan[];
}
