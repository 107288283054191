/**
 * 銀行コードとシステムコードのマッピング
 *
 * NOTE: DynamicConfig / DynamicComponentで使用する金融機関のみを記載している。
 */
export default {
  HGBA: { BANK_CODE: '0182', SYSTEM_CODE: 'HGBA' },
  KTBA: { BANK_CODE: '0158', SYSTEM_CODE: 'KTBA' },
  ZBA: { BANK_CODE: '9998', SYSTEM_CODE: 'ZBA' }
};
