var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFooter
    ? _c(
        "div",
        { staticStyle: { "margin-top": "80px" } },
        [
          _c("v-divider"),
          _vm._v(" "),
          _vm.$environment !== "PRODUCTION" && _vm.yappliRegisteredId
            ? _c("div", { staticClass: "pa-2 text-right" }, [
                _vm._v(
                  "\n    Yappli.sendRegisteredId = " +
                    _vm._s(_vm.yappliRegisteredId) +
                    "\n  "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMobile | !_vm.loggedIn
            ? _c(
                "div",
                [
                  _vm.appStore.context.state.appConfig.customize
                    .enable_footer_logo_img_visible
                    ? _c(
                        "v-row",
                        {
                          staticClass: "pt-5",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              contain: "",
                              "max-height": "40",
                              "max-width": "197.5",
                              src:
                                _vm.$config.assetUrl +
                                "/customize/" +
                                _vm.appStore.systemPrefix +
                                "/bank_logo.png",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.appStore.context.state.appConfig.customize
                    .enable_footer_bank_code_visible
                    ? _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "pt-1",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _vm._v(
                                "\n        金融機関コード：" +
                                  _vm._s(
                                    _vm.appStore.context.state.appConfig.bank
                                      .code
                                  ) +
                                  "\n      "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-footer",
                    {
                      staticClass: "mt-7",
                      attrs: { align: "center", dark: "", padless: "" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-1",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { "text-transform": "none" },
                              attrs: {
                                color: "white",
                                text: "",
                                to: "/page/rule",
                                nuxt: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.appStore.settingContents
                                      .footer_rule_link_string
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.appStore.settingContents.cookie_policy_contents
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "white",
                                    text: "",
                                    to: "/page/cookie_policy",
                                    nuxt: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          クッキーポリシー\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.appStore.context.state.appConfig.customize
                            .enable_footer_rc_rule_visible
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "white",
                                    text: "",
                                    to: "/page/rule_rc",
                                    nuxt: "",
                                  },
                                },
                                [_vm._v("\n          R&C会員規約\n        ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticStyle: { "text-transform": "none" },
                              attrs: {
                                color: "white",
                                text: "",
                                href: _vm.appStore.context.state.appConfig
                                  .privacy_url,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.appStore.settingContents
                                      .footer_privacy_policy_link_string
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "white",
                                text: "",
                                to: "/inquiry",
                                nuxt: "",
                              },
                            },
                            [_vm._v("\n          お問い合わせ\n        ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile
            ? [
                _vm.loggedIn && !_vm.appStore.appConfig.is_yappli_client
                  ? _c(
                      "div",
                      { staticClass: "sp-footer-menu" },
                      [
                        _c("NavigationMainButton", {
                          attrs: {
                            "icon-name": "mdi-menu",
                            title: "スタート",
                            selected:
                              _vm.mobileNavi.isShow &&
                              _vm.mobileNavi.title === "スタート",
                            "badge-content": _vm.totalUnreadCount,
                            width: 50,
                          },
                          on: {
                            "handle-click": function ($event) {
                              return _vm.handleMobileNavi("スタート")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.userStore.context &&
                        _vm.userStore.context.state.loginUser.user_type !==
                          "company_employee"
                          ? _c("NavigationMainButton", {
                              attrs: {
                                "icon-name":
                                  _vm.mobileNavi.isShow &&
                                  _vm.mobileNavi.title === "マッチング"
                                    ? "mdi-handshake"
                                    : "mdi-handshake-outline",
                                title: "マッチング",
                                selected:
                                  _vm.mobileNavi.isShow &&
                                  _vm.mobileNavi.title === "マッチング",
                                width: 50,
                                "badge-content": _vm.matchingUnrepliedCount,
                              },
                              on: {
                                "handle-click": function ($event) {
                                  return _vm.handleMobileNavi("マッチング")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("NavigationMainButton", {
                          attrs: {
                            "icon-name": "mdi-chat-outline",
                            title: "チャット",
                            width: 50,
                          },
                          on: {
                            "handle-click": function ($event) {
                              return _vm.handleMobileToPageByHref("/chat")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("NavigationMainButton", {
                          attrs: {
                            "icon-name":
                              _vm.mobileNavi.isShow &&
                              _vm.mobileNavi.title === "福利厚生"
                                ? "mdi-ticket-confirmation"
                                : "mdi-ticket-confirmation-outline",
                            title: "福利厚生",
                            selected:
                              _vm.mobileNavi.isShow &&
                              _vm.mobileNavi.title === "福利厚生",
                            width: 50,
                          },
                          on: { "handle-click": _vm.handleFukuri },
                        }),
                        _vm._v(" "),
                        _c("NavigationMainButton", {
                          attrs: {
                            "icon-name":
                              _vm.mobileNavi.isShow &&
                              _vm.mobileNavi.title === "サービス"
                                ? "mdi-dots-horizontal-circle"
                                : "mdi-dots-horizontal-circle-outline",
                            title: "サービス",
                            selected:
                              _vm.mobileNavi.isShow &&
                              _vm.mobileNavi.title === "サービス",
                            badge: _vm.isShowServiceBadge.value,
                            width: 50,
                          },
                          on: {
                            "handle-click": function ($event) {
                              return _vm.handleMobileNavi("サービス")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.loggedIn && _vm.appStore.appConfig.is_yappli_client
                  ? _c("div", { staticClass: "yappli-footer-menu" }, [
                      !_vm.mobileNavi.isShow
                        ? _c(
                            "div",
                            { staticClass: "yappli-btn d-flex justify-center" },
                            [
                              _c("NavigationMainButton", {
                                attrs: {
                                  width: 50,
                                  "icon-name": "mdi-menu",
                                  title: "スタート",
                                  selected: false,
                                  "badge-content":
                                    _vm.totalUnreadCount +
                                    _vm.totalMatchingCount,
                                },
                                on: {
                                  "handle-click": function ($event) {
                                    return _vm.handleMobileNavi("スタート")
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }