












































import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ApplicationBarToggleButton',
  props: {
    badgeCount: {
      type: Number,
      default: undefined
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const iconName = computed(() => props.open ? 'mdi-menu-open' : 'mdi-menu');

    return { iconName };
  }
});
