
































































































































































import { defineComponent, ref, onMounted, useRouter, useContext } from '@nuxtjs/composition-api';
import { useStore } from '@/compositions/store';
import { userStore, appStore } from '@/store';
import { openFukuri, isShowCampfireNewBadge, openPayment } from '@/utils';

export default defineComponent({
  name: 'AdminGlobalNavigation',
  setup() {
    const {
      globalNavi,
      isAdmin,
      switchingUser,
      changeUser,
      onResize,
      isMobile,
      unapprovedChatCount,
      noreplyChatCount,
      closedInquiryCount
    } = useStore();
    const showMenu = ref<boolean>(globalNavi);

    // 切替解除
    const router = useRouter();
    const cancelSwitching = () :void => {
      localStorage.setItem('user_switch_back', '2');
      router.push('/login');
    };

    const transferFukuri = (): void => {
      openFukuri();
    };

    const transferPayment = (): void => {
      openPayment();
    };

    onMounted(() => {
      onResize();
      if (!isMobile.value) {
        showMenu.value = true;
      }

      // 管理ユーザが切替中の場合
      const back = localStorage.getItem('user_switch_back');
      if (back) {
        changeUser(true);
      }
    });

    const ctx = useContext();
    const showCampfireNewBadge = ref<boolean>(isShowCampfireNewBadge(ctx.$formatDateTime((new Date()).toString(), 'YYYY-MM-DD HH:mm:ss')));

    return {
      userStore,
      showMenu,
      isAdmin,
      switchingUser,
      appStore,
      isMobile,
      onResize,
      unapprovedChatCount,
      noreplyChatCount,
      closedInquiryCount,
      cancelSwitching,
      transferFukuri,
      transferPayment,
      showCampfireNewBadge
    };
  }
});
