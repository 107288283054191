import request from '@/utils/api';

// BAMシステム定数データ取得
export const getBamAppConfig = (): any => {
  return request({
    url: process.env.BAM_BASE_URL + '/api/v1/app/config',
    method: 'get'
  });
};

// システム定数データ取得
export const getAppConfig = (): any => {
  // local環境時はBANK_CODEの設定があるのでその値を使ってAPIのクエリーパラメータとする
  // クエリーパラメータがない場合は下記APIでリファラーより金融機関が特定される(test,本番ではリファラーで特定)
  // ローカルでAPIの接続先を自由に設定できるようにした（デバッグのため）
  if (!process.env.BANK_CODE) {
    return request({
      url: self.$nuxt.context.$config.baAdminEndpoint + '/api/app/config',
      method: 'get'
    });
  } else {
    return request({
      url:
        self.$nuxt.context.$config.baAdminEndpoint +
        '/api/app/config?bank_code=' +
        process.env.BANK_CODE,
      method: 'get'
    });
  }
};

// カスタマイズ項目取得API
export const getSettingContents = (): any => {
  // local環境時はBANK_CODEの設定があるのでその値を使ってAPIのクエリーパラメータとする
  // クエリーパラメータがない場合は下記APIでリファラーより金融機関が特定される(test,本番ではリファラーで特定)
  // ローカルでAPIの接続先を自由に設定できるようにした（デバッグのため）
  if (!process.env.BANK_CODE) {
    return request({
      url: self.$nuxt.context.$config.baAdminEndpoint + '/api/app/setting_contents',
      method: 'get'
    });
  } else {
    return request({
      url:
        self.$nuxt.context.$config.baAdminEndpoint +
        '/api/app/setting_contents?bank_code=' +
        process.env.BANK_CODE,
      method: 'get'
    });
  }
};

// ユーザ別管理画面メニューデータ取得
export const getAdminMenu = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }

  return request({
    url: '/api/v1/navigation/menu',
    method: 'get'
  });
};

// ICNEXTサービス利用登録時URL取得API
export const getIcnextServiceRegistration = (): any =>
  request({
    url: process.env.BAM_BASE_URL + '/api/v1/icnext/service_registration',
    method: 'get'
  });

// ICNEXTユーザー登録時URL取得API
export const getIcnextUserRegistrationUrl = (): any =>
  request({
    url: process.env.BAM_BASE_URL + '/api/v1/icnext/user_registration',
    method: 'get'
  });

// ICNEXTログイン時URL取得API
export const getIcnextApplicationUrl = (): any =>
  request({
    url: process.env.BAM_BASE_URL + '/api/v1/icnext/application',
    method: 'get'
  });
