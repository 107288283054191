var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.badgeCount
        ? _c(
            "v-badge",
            {
              attrs: {
                color: "error",
                content: _vm.badgeCount,
                bordered: "",
                small: "",
                "offset-x": "25",
                "offset-y": "22",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "d-flex align-bottom justify-center",
                  attrs: {
                    rounded: "",
                    plain: "",
                    elevation: "0",
                    width: "50",
                    height: "50",
                    ripple: false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handle-click")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "30px" } }, [
                    _vm._v("\n        " + _vm._s(_vm.iconName) + "\n      "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "d-flex align-bottom justify-center",
              attrs: {
                rounded: "",
                plain: "",
                elevation: "0",
                width: "50",
                height: "50",
                ripple: false,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("handle-click")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "30px" } }, [
                _vm._v("\n      " + _vm._s(_vm.iconName) + "\n    "),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }