/* eslint-disable */
import { appStore } from '@/store';

// 直接アクセスされたかの設定
export default function ({ from, route }) {
  // from.name と route.name が一致した場合に直接アクセスと判断
  if (from.name === route.name) {
    appStore.setDirectAccess(true);
  } else {
    appStore.setDirectAccess(false);
  }
}
