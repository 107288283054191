/* eslint-disable */

import { getZendeskToken } from '@/apis';

export default async function ({ $auth, app, redirect, error }) {
  if (!$auth.loggedIn) {
    location.href = `/login?r=${app.context.route.fullPath}`;
  }

  const data = await getZendeskToken();
  if (!data || data.status !== 'success') {
    // ステータスがnullだった場合、もしくはステータスがエラーだった場合
    error({status: 500});
    return;
  }

  // ステータスが成功だった場合
  const returnTo = `${process.env.ZENDESK_SUBDOMAIN}${app.$config.zendeskReturnTo}`;
  const url = `${process.env.ZENDESK_SUBDOMAIN}/access/jwt?jwt=${data.result.token}&return_to=${returnTo}`;
  redirect(url);
}
