import axios from 'axios';
import request from '@/utils/api';
import { appStore } from '@/store';

/** ユーザ情報取得 */
export const getUserMe = (): any => {
  if (!localStorage.getItem('auth._token.bamidp') || localStorage.getItem('auth._token.bamidp') === 'false') {
    return null;
  }
  return request({
    url: '/api/v1/user/me',
    method: 'get'
  });
};

/** BAアカウント一覧取得 */
export const getMasterBaSystemAdmins = (): any => {
  if (!localStorage.getItem('auth._token.bamidp') || localStorage.getItem('auth._token.bamidp') === 'false') {
    return null;
  }
  return request({
    url: process.env.BA_ADMIN_URL + '/api/master/ba_system_admins',
    method: 'get'
  });
};

export const switchUser = (code: string): any => {
  const token = localStorage.getItem('auth._token.bamidp') || '';
  const bankCode = appStore.appConfig.bank_code;

  return axios({
    method: 'POST',
    url: process.env.IDP_BASE_URL + '/oidc/bank' + bankCode + '/token/switch',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: token
    },
    data: { user_unique_code: code }
  });
};

export const getAccessToken = (): any => {
  const token = localStorage.getItem('auth._token.bamidp') || '';
  const idToken = (token !== '') ? token.split(' ')[1] : '';
  return axios({
    method: 'POST',
    url:
      process.env.IDP_BASE_URL +
      '/oidc/token/use_idtoken',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: token
    },
    params: { id_token: idToken }
  });
};

export const getUserInfo = (accessToken: string): any => {
  return axios({
    method: 'POST',
    url:
      process.env.IDP_BASE_URL +
      '/oidc/bank/userinfo',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + accessToken
    }
  });
};

export const getRedirectTo = (): any => {
  const token = localStorage.getItem('auth._token.bamidp') || '';
  return axios({
    method: 'GET',
    url:
      '/api/v1/login/callback/bamidp',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: token
    }
  });
};

export const saveUserOperation = <T extends {}>(paramaters: T): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }

  return request({
    method: 'POST',
    url: '/api/v1/user/log/action',
    data: paramaters
  });
};

export const validateToken = (): any => {
  const token = localStorage.getItem('auth._token.bamidp') || '';
  const idToken = (token !== '') ? token.split(' ')[1] : '';
  const bankCode = appStore.appConfig.bank_code;

  return axios({
    method: 'GET',
    url: process.env.IDP_BASE_URL + '/oidc/bank' + bankCode + '/token/validate',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: token
    },
    params: { id_token: idToken }
  });
};

/** 経営相談ユーザー情報 */
export const getForumUserInfo = (): any => {
  if (!localStorage.getItem('auth._token.bamidp') || localStorage.getItem('auth._token.bamidp') === 'false') {
    return null;
  }
  return request({
    url: process.env.BACOM_URL + '/forum/api/v1/user',
    method: 'get'
  });
};

/** 経営相談ユーザー情報 */
export const getMallUserInfo = (): any => {
  if (!localStorage.getItem('auth._token.bamidp') || localStorage.getItem('auth._token.bamidp') === 'false') {
    return null;
  }
  return request({
    url: process.env.MALL_URL + '/mall/api/v1/user',
    method: 'get'
  });
};
