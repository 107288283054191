var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navigation-sub-btn" },
    [
      _vm.badge || _vm.badgeContent
        ? _c(
            "v-badge",
            {
              staticClass: "badge",
              attrs: {
                color: "error",
                dot: _vm.badge,
                content: _vm.badgeContent,
                "offset-x": _vm.badge && !_vm.badgeContent ? 20 : 25,
                "offset-y": _vm.badge && !_vm.badgeContent ? 30 : 35,
                bordered: "",
                small: "",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "navbtn d-flex justify-space-between pa-0",
                  class: { "-selected": _vm.selected, "-bold": _vm.fontBold },
                  attrs: {
                    rounded: "",
                    plain: "",
                    elevation: "0",
                    width: "100%",
                    height: "50",
                    ripple: false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handle-click")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "btncontent d-flex align-center" },
                    [
                      _vm._t("icon", function () {
                        return [_c("v-icon", [_vm._v(_vm._s(_vm.iconName))])]
                      }),
                      _vm._v(" "),
                      _c("span", { class: { small: _vm.fontSmall } }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.title) + "\n        "
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "navbtn d-flex justify-space-between pa-0",
              class: { "-selected": _vm.selected, "-bold": _vm.fontBold },
              attrs: {
                rounded: "",
                plain: "",
                elevation: "0",
                width: "100%",
                height: "50",
                ripple: false,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("handle-click")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "btncontent d-flex align-center" },
                [
                  _vm._t("icon", function () {
                    return [_c("v-icon", [_vm._v(_vm._s(_vm.iconName))])]
                  }),
                  _vm._v(" "),
                  _c("span", { class: { small: _vm.fontSmall } }, [
                    _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                  ]),
                ],
                2
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }