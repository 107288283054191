import { NavigationItem } from '@/configs';

/**
 * 現在のページパスがメインメニュー項目に含まれているかどうか判定する
 * @param item
 * @param currentPath 現在のパス
 * @param currentHash 現在のハッシュ
 */
export const isMainItemSelected = (item: NavigationItem, currentPath: string, currentHash?: string): boolean => {
  // サブメニューがない場合はメインのパスと同じかどうか確認する
  if (!item.sub) {
    return item.path === currentPath + currentHash;
  }
  // サブメニューの中に選択中のものがあるかどうか
  const selected = item.sub.some((item) => isSubItemSelected(item, currentPath, currentHash));
  // 従業員アカウント設定ページが福利厚生と設定で2箇所あるためどちらが選択されているかハッシュを確認する
  if (currentHash === '#fukuri' || currentHash === '#setting') {
    return selected && item.hash === currentHash;
  }
  return selected;
};

/**
 * サブメニューが選択中か鑑定する
 * @param item
 * @param currentPath 現在のパス
 * @param currentHash 現在のハッシュ
 */
export const isSubItemSelected = (item: NavigationItem, currentPath: string, currentHash?: string): boolean => {
  // 従業員アカウント設定ページが福利厚生と設定で2箇所あるためどちらが選択されているかをハッシュで確認する
  if (currentHash === '#fukuri' || currentHash === '#setting') {
    return (
      item.path === currentPath + currentHash ||
      !!item.related_path?.some((path) =>
        currentPath.startsWith(path)
      )
    );
  }
  return (
    currentPath.startsWith(item.path) ||
    !!item.related_path?.some((path) => currentPath.startsWith(path))
  );
};
