var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("v-toolbar-title", [
        _c(
          "div",
          { staticClass: "heading main-caption" },
          [
            _c(
              "v-row",
              [
                _c("v-col", [_vm._v("\n          確認\n        ")]),
                _vm._v(" "),
                _c("v-col", { staticClass: "text-right" }, [
                  _c("i", {
                    staticClass: "mdi mdi-close white--text",
                    attrs: { "x-large": "" },
                    on: {
                      click: function ($event) {
                        _vm.dialog = false
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "pa-10", attrs: { height: "350" } },
        [
          _c("v-card-text", [
            _c("img", {
              staticClass: "pa-10",
              attrs: { src: "/assets/img/icnext/logo_icnext.png" },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "pl-8" }, [
              _vm._v(
                "\n        ネクストICカードのサービスサイトを表示します。"
              ),
              _c("br"),
              _vm._v("よろしいですか？\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.isApiCalling,
                    href: _vm.appStore.icnextApplicationUrl,
                    target: "_blank",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setIcnextModal(!_vm.donotDisplayAgain)
                    },
                  },
                },
                [_vm._v("\n        ネクストICカードへ\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "pt-5", attrs: { justify: "center" } },
            [
              _c("v-checkbox", {
                attrs: { label: "今後この確認を表示しない" },
                model: {
                  value: _vm.donotDisplayAgain,
                  callback: function ($$v) {
                    _vm.donotDisplayAgain = $$v
                  },
                  expression: "donotDisplayAgain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }