


















































































import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import ApplicationBar from '@/components/layout/applicationbar.vue';

export default defineComponent({
  components: { ApplicationBar },
  layout: 'plain',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const router = useRouter();

    const redirect = (): void => {
      if (props.error.statusCode === 401) {
        window.setTimeout(function () {
          router.push('/logout?to=login');
        }, 3000);
      }
    };
    redirect();
  }
});
