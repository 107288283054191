








































import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { appStore } from '@/store';
import { useStore } from '@/compositions/store';

export default defineComponent({
  setup() {
    const state = reactive<any>({
      dialog: true,
      donotDisplayAgain: true,
      isApiCalling: true
    });
    const { setIcnextModal, addToastMessage } = useStore();

    if (appStore.icnextApplicationUrl === '') {
      addToastMessage('データ取得に失敗しました。', 'error');
      state.isApiCalling = false;
    }

    return {
      ...toRefs(state),
      appStore,
      setIcnextModal
    };
  }
});
