const middleware = {}

middleware['authentication'] = require('../middleware/authentication.js')
middleware['authentication'] = middleware['authentication'].default || middleware['authentication']

middleware['direct-access'] = require('../middleware/direct-access.js')
middleware['direct-access'] = middleware['direct-access'].default || middleware['direct-access']

middleware['routeAccessPermissionGuard'] = require('../middleware/routeAccessPermissionGuard.ts')
middleware['routeAccessPermissionGuard'] = middleware['routeAccessPermissionGuard'].default || middleware['routeAccessPermissionGuard']

middleware['zendesk'] = require('../middleware/zendesk.js')
middleware['zendesk'] = middleware['zendesk'].default || middleware['zendesk']

export default middleware
