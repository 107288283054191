var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c(
        "v-app",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          style: _vm.ajustment,
          attrs: { dark: "" },
        },
        [
          _c("noscript", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.gtm_body) } }),
          ]),
          _vm._v(" "),
          _c("noscript", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.gtm_body2) } }),
          ]),
          _vm._v(" "),
          _vm.testBar() ? _c("div", { staticClass: "test-border" }) : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.toastMessages, function (messages, type) {
            return _c(
              "div",
              { key: type },
              _vm._l(messages, function (show, message) {
                return _c(
                  "div",
                  { key: message },
                  [
                    _c(
                      "v-snackbar",
                      {
                        attrs: {
                          color: type,
                          timeout: "5000",
                          "content-class": "text-center",
                        },
                        model: {
                          value: _vm.toastMessages[type][message],
                          callback: function ($$v) {
                            _vm.$set(_vm.toastMessages[type], message, $$v)
                          },
                          expression: "toastMessages[type][message]",
                        },
                      },
                      [_c("b", [_vm._v(_vm._s(message))])]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          }),
          _vm._v(" "),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-main",
            [
              _c("nuxt"),
              _vm._v(" "),
              _c("Footer", {
                attrs: { dialog: _vm.dialog },
                on: {
                  close: function ($event) {
                    _vm.dialog = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }