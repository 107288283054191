/** 一覧のページング設定 */
export const PAGINATION_OPTIONS = {
  enabled: true,
  mode: 'pages',
  position: 'top',
  nextLabel: '次ページ',
  prevLabel: '前ページ',
  rowsPerPageLabel: '表示行数',
  perPageDropdown: [5, 10, 25, 50, 100, 500],
  dropdownAllowAll: false,
  perPage: 25,
  setCurrentPage: 1
};

/** 一覧のページング設定 */
export const MAX_ROWS = [
  {
    value: 25,
    text: '25'
  },
  {
    value: 50,
    text: '50'
  },
  {
    value: 100,
    text: '100'
  },
  {
    value: 500,
    text: '500'
  }
];

/** 一度に取得できる一覧レコード件数 */
export const MAX_LIMIT = 500;

/** 2段階認証の状態 */
export const TWO_FACTOR_AUTH = {
  disable: 0,
  test: 1,
  enable: 2,
  lock: 3
};
