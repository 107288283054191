




























































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  useContext,
  ref,
  nextTick,
  PropType,
  useRoute,
  computed
} from '@nuxtjs/composition-api';
import { appStore, userStore } from '@/store';
import { useStore } from '@/compositions/store';
import {
  isShowBusinessSuccessionNewBadge,
  isShowCampfireNewBadge,
  isShowIcnextLinkageNewBadge,
  isShowPaymentNewBadge,
  openFukuri,
  openPayment,
  testBar
} from '@/utils';
import NavigationSubButton from '@/components/layout/navigation-sub-button.vue';
import {
  NavigationItem,
  PAYMENT_MENU,
  CHANTO_ATTENDANCE_EMPLOYEE_MENU,
  FUKURI_READ_MENU,
  USER_TYPE
} from '@/configs';
import { isSubItemSelected } from '@/compositions/sidenavi';

export default defineComponent({
  name: 'FooterNavi',
  components: { NavigationSubButton },
  props: {
    items: {
      type: Array as PropType<NavigationItem[]>,
      default: () => []
    }
  },
  setup(props) {
    const {
      showFooter,
      isMobile,
      mobileNavi,
      yappliRegisteredId,
      openSC,
      device,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      matchingUnrepliedCount
    } = useStore();
    const adjustment = testBar() && !appStore.appConfig.is_yappli_client
      ? 'margin-top: 12px'
      : '';

    const loggedIn = ref<boolean>(false);

    const ctx = useContext();
    loggedIn.value = ctx.$auth.loggedIn;

    const transferFukuri = (): void => {
      openFukuri();
    };

    const transferPayment = (): void => {
      openPayment();
    };

    const route = useRoute();
    const currentPath = computed(() => route.value.path);
    // 従業員アカウント設定が「福利厚生」と「設定」にあるため遷移先を識別するために使用
    const currentHash = computed(() => route.value.hash);

    const getSubItems = (title: string): NavigationItem[] => {
      const item = props.items.find(
        (item: NavigationItem) => item.title === title
      );
      return item?.sub ? item.sub : [];
    };

    const getItem = (title: string): NavigationItem | undefined => {
      return props.items.find((item: NavigationItem) => item.title === title);
    };

    const handleMobileNavi = (title: string): void => {
      // 既にクリックされたボタンのナビが開いていれば閉じるだけ
      if (mobileNavi.value.isShow && mobileNavi.value.title === title) {
        mobileNavi.value.isShow = false;
        return;
      }
      mobileNavi.value.isShow = false;
      nextTick(() => {
        mobileNavi.value.title = title;
        mobileNavi.value.isShow = true;
      });
    };

    /**
     * 指定されたパスに同期的遷移を行う
     * @param path パス
     */
    const handleMobileToPageByHref = (path: string): void => {
      window.location.href = path;
    };

    // いつクラウドファウンディングを確認したかのフラグを追加(存在していたらバッジを出すか出さないかのを確認)
    const showCampfireNewBadge = ref<boolean>(
      isShowCampfireNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつ事業承継を確認したかのフラグを追加(存在していたらバッジを出すか出さないかのを確認)
    const showBusinessSuccessionNewBadge = ref<boolean>(
      isShowBusinessSuccessionNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつ勤怠連携機能を確認したかのフラグを追加(存在していたらバッジを出すか出さないかのを確認)
    const showAttendanceLinkageNewBadge = ref<boolean>(
      isShowIcnextLinkageNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつちゃんと勤怠を確認したかのフラグを追加(存在していたらバッジを出すか出さないかのを確認)
    const showPaymentNewBadge = ref<boolean>(
      isShowPaymentNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // バッジに表示する未読数を取得する
    const getBadgeCount = (item: NavigationItem): number | undefined => {
      // 金融機関連携チャット
      if (item.path === '/mypage/bachat' && bachatRoomsUnreadCount.value > 0) {
        return bachatRoomsUnreadCount.value;
      }
      // ビジネスチャット
      if (item.path === '/chat' && chatRoomsUnreadCount.value > 0) {
        return chatRoomsUnreadCount.value;
      }
      // お知らせ・ご案内
      const userType = userStore.context.state.loginUser.user_type;
      if (
        (userType === USER_TYPE.OWNER || userType === USER_TYPE.USER || userType === USER_TYPE.PARTNER_OWNER) &&
        item.path === '/mypage/news' && unreadCmiCount.value > 0
      ) {
        return unreadCmiCount.value;
      }
      // 商談管理
      if (item.path === '/mypage/matching/received_history' && matchingUnrepliedCount.value > 0) {
        return matchingUnrepliedCount.value;
      }
      return undefined;
    };

    return {
      loggedIn,
      appStore,
      userStore,
      showFooter,
      isMobile,
      mobileNavi,
      transferFukuri,
      yappliRegisteredId,
      handleMobileNavi,
      handleMobileToPageByHref,
      getSubItems,
      currentPath,
      currentHash,
      openSC,
      device,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      showBusinessSuccessionNewBadge,
      showAttendanceLinkageNewBadge,
      showPaymentNewBadge,
      showCampfireNewBadge,
      transferPayment,
      adjustment,
      getItem,
      PAYMENT_MENU,
      CHANTO_ATTENDANCE_EMPLOYEE_MENU,
      FUKURI_READ_MENU,
      matchingUnrepliedCount,
      isSubItemSelected,
      getBadgeCount
    };
  }
});
