import { USER_TYPE } from '@/configs';
import { OPERATION_PERMISSIONS } from '@/configs/enums/permissions';
/** アクセス権限の定義 */
export interface IAccessPermission {
  /** ルートパス */
  routePath: string;
  /** 必要なアクセス条件 */
  accessRequirements: {
    /** 権限 */
    permissions: OPERATION_PERMISSIONS[];
    /**
     * 許可されているユーザータイプの一覧
     * - 空の場合、全てのユーザータイプに対してアクセスが許可される。
     */
    allowedUserTypes: (typeof USER_TYPE)[keyof typeof USER_TYPE][];
  };
  /** エラー */
  errorInfo: {
    statusCode: number;
    errorMessage: string;
  };
}
