import { render, staticRenderFns } from "./navigation.vue?vue&type=template&id=4d49556e&scoped=true&"
import script from "./navigation.vue?vue&type=script&lang=ts&"
export * from "./navigation.vue?vue&type=script&lang=ts&"
import style0 from "./navigation.vue?vue&type=style&index=0&id=4d49556e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d49556e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBadge,VDivider,VIcon,VList,VListGroup,VListItem,VListItemTitle,VNavigationDrawer,VSheet})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d49556e')) {
      api.createRecord('4d49556e', component.options)
    } else {
      api.reload('4d49556e', component.options)
    }
    module.hot.accept("./navigation.vue?vue&type=template&id=4d49556e&scoped=true&", function () {
      api.rerender('4d49556e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/admin/layout/navigation.vue"
export default component.exports