import { render, staticRenderFns } from "./navigation.vue?vue&type=template&id=8d3bf424&scoped=true&"
import script from "./navigation.vue?vue&type=script&lang=ts&"
export * from "./navigation.vue?vue&type=script&lang=ts&"
import style0 from "./navigation.vue?vue&type=style&index=0&id=8d3bf424&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d3bf424",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VItem,VItemGroup,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8d3bf424')) {
      api.createRecord('8d3bf424', component.options)
    } else {
      api.reload('8d3bf424', component.options)
    }
    module.hot.accept("./navigation.vue?vue&type=template&id=8d3bf424&scoped=true&", function () {
      api.rerender('8d3bf424', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/layout/navigation.vue"
export default component.exports