/**
 * データ登録で利用
 *
 * APIの実行
 * 登録時の画面ロック及び成功・エラーメッセージ表示等
 * @module compositions/base-save
 *
 */
import { reactive, toRefs } from '@nuxtjs/composition-api';
import { requestApi } from '@/apis';
import { ISavetResponse } from '@/types';
import { useStore } from '@/compositions/store';
import { appStore } from '@/store';
import { now, formatDate, compareDate } from '@/utils/filter';

interface State {
  url: string;
  method: string;
  useResponseMessage: boolean;
  success: string;
  error: string;
  errors: any;
  doToast: boolean;
  successWithMessage: boolean;
  loaded: boolean;
  saveError: boolean;
  valid: boolean;
  lazyValidation: boolean;
  sleepTime: number;
}

/**
 * 登録処理
 *
 * @return {*}  {*}
 */
const Save = (): any => {
  const state = reactive<State>({
    url: '',
    method: 'post',
    useResponseMessage: false,
    success: '登録が完了しました',
    error: 'データの登録に失敗しました',
    errors: {},
    doToast: false,
    successWithMessage: false,
    loaded: false,
    saveError: false,
    valid: true,
    lazyValidation: false,
    sleepTime: 0
  });
  const { setOverlay, addToastMessage } = useStore();

  /**
   * 登録処理API設定
   *
   * @param {string} url
   * @param {{
   *       method: string; APIのmethod
   *       useResponseMessage: boolean; エラー時レスポンスのメッセージをエラーメッセージとするか
   *       success: string; 登録成功メッセージ
   *       error: string; 登録失敗メッセージ
   *       doToast: boolean; 完了及びエラーをtoastで表示するか
   *       successWithMessage: boolean 登録成功時にmessageが返却される場合はtrueを設定する
   *       getFromBam?: boolean, APIのエンドポイントがBAMか
   *     }} [option]
   * @return {*}  {void}
   */
  const setSaveConfig = (
    url: string,
    option?: {
      method: string;
      useResponseMessage: boolean;
      success: string;
      error: string;
      doToast: boolean;
      successWithMessage: boolean;
      getFromBam: boolean;
      sleepTime: number;
    }
  ): void => {
    state.url = url;

    if (option) {
      if (option.method) {
        state.method = option.method;
      }

      if (option.useResponseMessage) {
        state.useResponseMessage = option.useResponseMessage;
      }

      if (option.doToast) {
        state.doToast = option.doToast;
      }

      if (option.success) {
        state.success = option.success;
      }

      if (option.error) {
        state.error = option.error;
      }

      if (option.successWithMessage) {
        state.successWithMessage = option.successWithMessage;
      }

      if (option.getFromBam) {
        state.url = appStore.appConfig.bamanager_url + state.url;
      }

      if (option.sleepTime) {
        state.sleepTime = option.sleepTime;
      }
    }
  };

  // Submit前の入力チェックを行わないか否か true:行わない
  const setLazyValidation = (validation: boolean): void => {
    state.lazyValidation = validation;
  };

  /**
   * APIの実行
   *
   * @template T
   * @param {T} [paramaters] postデータ
   * @param {string} [url] 登録APIのURL
   * @return {*}  {Promise<any>}
   */
  const saveData = async <T extends {}>(paramaters: {
    data: T;
    url?: string;
  }): Promise<any> => {
    setOverlay(true);
    state.saveError = false;

    if (paramaters && paramaters.url) {
      state.url = paramaters.url;
    }
    let result;
    // API実行
    await requestApi(state.url, paramaters.data, state.method)
      .then(async (data: ISavetResponse) => {
        if (state.sleepTime > 0) {
          await new Promise(resolve => setTimeout(resolve, state.sleepTime));
        }
        if (data && data.status === 'success') {
          state.saveError = false;
          // 成功時にはresultにnullが返って来る(成功の時でもnullじゃないときがあるのでその時はsuccessWithMessageをtrueに)
          if (!data.result || state.successWithMessage) {
            if (state.doToast) {
              if (state.useResponseMessage && data && data.message) {
                state.success = data.message;
              }
              addToastMessage(state.success);
            }
            state.loaded = true;
            setOverlay(false);
            result = data.result;
          } else {
            if (state.useResponseMessage) {
              if (data.error_message) {
                state.error = data.error_message;
                if (state.doToast) {
                  addToastMessage(state.error, 'error');
                  state.saveError = true;
                }
              } else if (state.doToast) {
                if (data.message) {
                  state.success = data.message;
                }
                addToastMessage(state.success, 'success');
              }
            }
            state.loaded = true;
            setOverlay(false);
            result = data;
          }
        } else {
          if (state.useResponseMessage) {
            if (data && 'message' in data && data.message) {
              state.error = data.message;
            } else if (data && 'error_message' in data && data.error_message) {
              state.error = data.error_message;
            } else if (data && data.result) {
              // result内にエラー内容が入ってくる場合がある BAX-1552
              if (
                'error_messages' in data.result &&
                data.result.error_messages
              ) {
                state.errors = data.result.error_messages;
              }
            }
            if (data && data.errors) {
              state.errors = data.errors;
            }
          }
          // 予期せぬエラー発生
          if (state.doToast) {
            addToastMessage(state.error, 'error');
          }
          state.saveError = true;
          state.loaded = true;
          setOverlay(false);
          return false;
        }
      })
      .catch((e: any) => {
        if (state.doToast) {
          addToastMessage(state.error, 'error');
        }
        console.error('データ登録APIエラー', e);
        state.saveError = true;
        state.loaded = true;
        setOverlay(false);
        return false;
      });
    return result;
  };

  /**
   * 必須入力チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isRequired = (value: any): string | boolean => {
    if (state.lazyValidation) {
      return true;
    }
    return !!value || '必須入力項目です';
  };

  /**
   * 複数項目のうちどれか一つ必須である場合の入力チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const someRequired = (value: any): string | boolean => {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (value[i]) {
          return true;
        }
      }
      return '必須入力項目です';
    } else {
      return !!value || '必須入力項目です';
    }
  };

  /**
   * 必須入力チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberRequired = (value: any): string | boolean => {
    if (state.lazyValidation) {
      return true;
    }
    if (!String(value).match(/^[0-9]+$/)) {
      return '必須入力項目です';
    }
    return true;
  };

  /**
     * 電話番号チェック
     *
     * @param {*} value
     * @return {*}  {(string | boolean)}
     */
  const isPhoneNumber = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^\d{2,4}-\d{2,4}-\d{3,4}$/)) {
      return '電話番号が正しくありません';
    }
    return true;
  };

  /**
   * メールフォーマットチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   *
   * Extracted from PHP core.
   * @link https://github.com/php/php-src/blob/master/ext/filter/logical_filters.c
   */
  const isEmail = (value: any): string | boolean => {
    if (!value) {
      return true;
    }

    if (
      // !String(value).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      // php_filter_validate_emailの正規表現と合わせる（RFC822）
      /* eslint-disable no-control-regex */
      !String(value).match(
        /^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-+[a-z0-9]+)*\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-+[a-z0-9]+)*)|(?:\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/i
      )
    ) {
      return 'メールアドレスが正しくありません';
    }
    return true;
  };

  /**
   * 半角数字(小数点を含む)チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isDecimal = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[-]?([1-9]\d*|0)(\.\d+)?$/)) {
      return '半角数字で入力してください';
    }
    return true;
  };

  /**
   * 半角数字・半角ハイフンチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberHyphen = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[0-9-]+$/)) {
      return '半角数字と半角ハイフンで入力してください';
    }
    return true;
  };

  /**
   * カナチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isKana = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!value.match(/^[ァ-ヶー]*$/)) {
      return 'カナを入力してください';
    }
    return true;
  };

  /**
   * 半角数字チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumber = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[0-9]+$/)) {
      return '半角数字で入力してください';
    }
    return true;
  };

  /**
   * 半角英数字チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isAlphanumeric = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[0-9a-zA-Z]+$/)) {
      return '半角英数字で入力してください';
    }
    return true;
  };

  /**
   * 金額が（1以上）チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberPlusRequired = (value: any): string | boolean => {
    if (value <= 0) {
      return '1以上の整数を半角で入力してください';
    }
    if (!String(value).match(/^[0-9]+$/)) {
      return '1以上の整数を半角で入力してください';
    }
    return true;
  };

  /**
   * 金額(±)チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberPlusOrMinusRequired = (value: any): string | boolean => {
    if (!String(value).match(/^[0-9-]+$/)) {
      return '整数を半角で入力してください';
    }
    return true;
  };

  /**
   * 24時間表記制時刻チェック
   *
   * @param {*} value
   * @return {*} {(string|boolean)}
   */
  const isTime24HourNotation = (value: any): string | boolean => {
    if (!value) {
      return true;
    }

    if (!String(value).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
      return '半角数字とコロン・24時間表記で入力してください　例：18:00';
    }

    return true;
  };

  /**
   * 24時間表記制開始時刻チェック
   *
   * @param {*} value
   * @param {*} value2
   * @return {*} {(string|boolean)}
   */
  const isTime24HourNotationStart = (
    value: any,
    value2: any
  ): string | boolean => {
    if (!value && !value2) {
      return true;
    }

    if (!String(value).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
      return '半角数字とコロン・24時間表記で入力してください　例：18:00';
    }

    if (!value2) {
      return '終了時間も入力してください';
    }

    const times = String(value).split(':');
    const times2 = String(value2).split(':');

    if (
      Number(times[0]) > Number(times2[0]) ||
      (Number(times[0]) === Number(times2[0]) &&
        Number(times[1]) > Number(times2[1]))
    ) {
      return '終了時間が開始時間より遅くなるように入力してください';
    }

    return true;
  };

  /**
   * 24時間表記制終了時刻チェック
   *
   * @param {*} value
   * @param {*} value2
   * @return {*} {(string|boolean)}
   */
  const isTime24HourNotationEnd = (
    value: any,
    value2: any
  ): string | boolean => {
    if (!value && !value2) {
      return true;
    }

    if (!String(value2).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
      return '半角数字とコロン・24時間表記で入力してください　例：18:00';
    }

    if (!value) {
      return '開始時間も入力してください';
    }

    const times = String(value).split(':');
    const times2 = String(value2).split(':');

    if (
      Number(times[0]) > Number(times2[0]) ||
      (Number(times[0]) === Number(times2[0]) &&
        Number(times[1]) > Number(times2[1]))
    ) {
      return '終了時間が開始時間より遅くなるように入力してください';
    }

    return true;
  };

  /**
   * 最大文字数チェック
   *
   * @param {*} value
   * @param {*} max
   * @return {*} {(string|boolean)}
   */
  const isMaxLen = (value: any, max: number): string | boolean => {
    if (!value) {
      return true;
    }

    if (String(value).length > max) {
      return `${max}文字以内で入力してください`;
    }

    return true;
  };

  /**
   * 指定の項目以降の日付か
   * YYYY-MM-DDで比較
   *
   * @param {*} value
   * @param {*} comparison
   * @return {*} {(string|boolean)}
   */
  const isSameOrAfter = (value: any, comparison: any = 'now'): string | boolean => {
    if (!comparison || !value) {
      return true;
    }

    const compare = comparison === 'now' ? now('YYYY-MM-DD') : formatDate(comparison);

    if (state.lazyValidation) {
      return true;
    }

    if (compareDate(compare, value, '>=')) {
      return true;
    }

    return '入力が過去日です';
  };

  /**
   * 指定の時間以降の時間か
   */
  const isDateTimeLaterThanNow = (value: string, errorMessage: string): boolean | string => {
    const now = new Date().toISOString();

    return compareDate(now, value, '>=') || errorMessage;
  };

  /**
   * 配列が空でないことをチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isArrayNonEmpty = (value: any[]): string | boolean => {
    if (state.lazyValidation) {
      return true;
    }
    if (!Array.isArray(value) || value.length === 0) {
      return '必須入力項目です。';
    }
    return true;
  };

  return {
    isRequired,
    someRequired,
    isNumberRequired,
    isPhoneNumber,
    isEmail,
    isDecimal,
    isNumberHyphen,
    isKana,
    isNumber,
    isAlphanumeric,
    isNumberPlusRequired,
    isNumberPlusOrMinusRequired,
    isTime24HourNotation,
    isTime24HourNotationStart,
    isTime24HourNotationEnd,
    isMaxLen,
    isSameOrAfter,
    isArrayNonEmpty,
    setSaveConfig,
    saveData,
    setLazyValidation,
    isDateTimeLaterThanNow,
    ...toRefs(state)
  };
};

export { Save };
