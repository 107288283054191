import { userStore } from '@/store';
import { routeAccessPermissions } from '@/configs/middleware/routeAccessPermissions';

/**
 * 現在のパスに対してログインユーザーが必須権限を持っているかチェック
 *
 * @param {object} context.route 現在のルート
 * @param {object} context.error エラー
 */
export default function ({ route, error }: { route: any; error: any }): void {
  const currentPath = route.path;
  const currentPathPermission = routeAccessPermissions.find(permission => currentPath.startsWith(permission.routePath));

  // スーパーユーザーの場合は権限チェックをスキップ
  const loginUser = userStore.loginUser;
  if (loginUser?.user_is_super_user) {
    return;
  }

  // アクセス権限が定義されていない場合はスキップ
  if (!currentPathPermission) {
    return;
  }

  // ユーザーのもっている権限
  const hasUserPermissions = userStore.permittedActions;

  // パーミッションが配列でない場合はエラー
  if (!Array.isArray(hasUserPermissions)) {
    console.error('invalid permittedActions');
    error({
      statusCode: currentPathPermission.errorInfo.statusCode,
      message: currentPathPermission.errorInfo.errorMessage
    });
    return;
  }

  // 必要な権限を持っているか確認
  const hasRequiredPermissions = currentPathPermission.accessRequirements.permissions.every(
    action => hasUserPermissions.includes(action)
  );

  // アクセス可能なユーザータイプであるか確認
  const isAllowedUserType =
    currentPathPermission.accessRequirements.allowedUserTypes.length === 0 ||
    currentPathPermission.accessRequirements.allowedUserTypes.includes(loginUser.user_type);

  if (!hasRequiredPermissions || !isAllowedUserType) {
    error({
      statusCode: currentPathPermission.errorInfo.statusCode,
      message: currentPathPermission.errorInfo.errorMessage
    });
  }
}
