export default [
  {
    label: '',
    field: 'company_unique_code',
    html: true,
    sortable: false,
    width: '50px',
    tdClass: 'text-center'
  },
  {
    label: '企業ID',
    field: 'company_id',
    type: 'number'
  },
  {
    label: '企業名',
    field: 'company_name',
    type: 'string'
  },
  {
    label: 'company_type',
    field: 'company_type',
    hidden: true
  },
  {
    label: '担当者名',
    field: 'company_owner_user_last_name',
    type: 'string'
  },
  {
    label: 'user_first_name',
    field: 'company_owner_user_first_name',
    hidden: true
  },
  {
    label: 'company_owner_user_unique_code',
    field: 'company_owner_user_unique_code',
    hidden: true
  },
  {
    label: '取引支店名',
    field: 'bb_name',
    type: 'string'
  },
  {
    label: 'ニーズ数',
    field: 'company_matching_count',
    type: 'number',
    description: '登録しているニーズ数（非公開含む）'
  },
  {
    label: 'クーポン数',
    field: 'company_coupon_num',
    type: 'number',
    description: '提供中かつ有効期限内のクーポンの件数'
  },
  {
    label: 'ユーザ数',
    field: 'company_member_count',
    type: 'number',
    description: '企業オーナー・企業ビジネスユーザー・企業従業員の数'
  },
  {
    label: 'ビジネスユーザ数',
    field: 'company_business_user_count',
    type: 'number',
    description: '登録されている企業ビジネスユーザーの数'
  },
  {
    label: 'HP公開',
    field: 'company_websites',
    tdClass: 'text-center',
    description: 'HPの公開状況です。「公開中」はHPにリンクします'
  },
  {
    label: '課金',
    field: 'company_charge_amount',
    tdClass: 'text-center',
    description: '設定されている月の課金金額です'
  },
  {
    label: '課金状況',
    field: 'company_is_partner',
    tdClass: 'text-center',
    description: '未収がある場合「未収あり」と表記されます'
  },
  {
    label: '登録日',
    field: 'company_created_at',
    tdClass: 'text-center'
  },
  {
    label: '最終利用日',
    field: 'owner_last_access_date',
    tdClass: 'text-center'
  }
];
