/**
 * store グローバルに値を保持する変数等を登録
 *
 * コンポーネント間でグローバル利用するマスターデータやシステム設定データ等APIのレスポンスが常に変わらないようなデータを登録してください。
 *
 * APIのレスポンスが都度変わるものに関しては/compositions/store.ts /compositions/admin/store.tsで登録してください
 * @module store/index
 */
import { Store } from 'vuex';
import { initialiseStores } from '@/utils/store-accessor';

const initializer = (store: Store<any>): void => initialiseStores(store);
export const plugins = [initializer];
export * from '@/utils/store-accessor';
