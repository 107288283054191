import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import App from '@/store/app';
import Master from '@/store/master';
import User from '@/store/user';
import Auth from '@/store/auth';

// eslint-disable-next-line import/no-mutable-exports
let appStore: App;
// eslint-disable-next-line import/no-mutable-exports
let masterStore: Master;
// eslint-disable-next-line import/no-mutable-exports
let userStore: User;
// eslint-disable-next-line import/no-mutable-exports
let authStore: Auth;

/**
 * storeの初期化
 *
 * @param {Store<any>} store ストア
 * @see {@link https://typescript.nuxtjs.org/ja/cookbook/store/} vuex-module-decorators
 */
function initialiseStores(store: Store<any>): void {
  appStore = getModule(App, store);
  masterStore = getModule(Master, store);
  userStore = getModule(User, store);
  authStore = getModule(Auth, store);
}

export { initialiseStores, appStore, masterStore, userStore, authStore };
