




































































































































import { defineComponent, ref, useMeta, useRoute, watch, useContext, onMounted, watchEffect } from '@nuxtjs/composition-api';
import AdimnGlobalNavigation from '@/components/admin/layout/navigation.vue';
import Footer from '@/components/admin/layout/footer.vue';
import { provideStore, useStore } from '@/compositions/store';
import { appStore, masterStore, userStore } from '@/store';
import service from '@/utils/api';
import { getBamAppConfig } from '@/apis/config';
import { getGoogleTagMangerHeaderSetting, getGoogleTagMangerBodySetting, getGoogleTagMangerBodySetting2, getAdminPendoHeaderSetting, isNeedYappli, isLocalStorageavAilable, testBar } from '@/utils';
import { getManualForBankAdmin } from '@/apis';

import '@/assets/css/admin/style.sass';

export default defineComponent({
  components: { AdimnGlobalNavigation, Footer },
  middleware: 'authentication',
  setup() {
    // provideにキー付きでリアクティブな状態を登録(各コンポーネントで利用可能となる)
    provideStore();

    const {
      isMobile,
      globalNavi,
      setAdmin,
      isAdmin,
      overlay,
      toastMessages,
      setunapprovedChatCount,
      setNoreplyChatCount,
      setClosedInquiryCount,
      unapprovedChatCount,
      noreplyChatCount,
      closedInquiryCount,
      yappliRegisteredId,
      setYappliInfo,
      addToastMessage
    } = useStore();
    setAdmin(true);

    const showMenu = ref<boolean>(globalNavi);
    const initialized = ref<boolean>(false);
    const mounted = ref<boolean>(false);
    const registeredId = ref<boolean>(false);
    const isShowManualModal = ref<boolean>(false);

    const { meta, link, script } = useMeta();
    const ctx = useContext();
    const route = useRoute();

    const gaId = ref<string>('');
    const ajustment = testBar() ? 'height: 64px; top:12px' : 'height: 64px; top:0';
    const mainAjustment = testBar() ? 'pt-6 px-8' : 'px-8';

    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (appStore.appConfig && appStore.appConfig.ga_id && typeof gtag === 'function') {
          gaId.value = appStore.appConfig.ga_id;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }
      }
    );

    watchEffect(() => {
      if (!registeredId.value && yappliRegisteredId.value && mounted.value) {
        Yappli.sendRegisteredId(userStore.loginUser.user_unique_code);
        registeredId.value = true;
      }
    });

    const gtm_body = ref<string>('');
    const gtm_body2 = ref<string>('');
    // システム定義/カスタマイズ項目取得一時的な対応（サーバーサイドのマルチテナント化完了まで）
    getBamAppConfig()
      .then((r: any) => {
        if (r && r.result && r.result.api_url) {
          appStore.SET_BASE_URL(r.result.api_url);
        } else if (process.env.NAME === 'DEVELOPMENT' || process.env.NAME === 'STAGING' || process.env.NAME === 'PRODUCTION') {
          ctx.$sentry.captureMessage('サーバ情報が取得特できませんでした。isLocalStorageavAilable:' + isLocalStorageavAilable());
          return;
        } else {
          appStore.SET_BASE_URL(process.env.API_URL || '');
        }
        service.defaults.baseURL = appStore.baseUrl;
      })
      .then(() => {
        const reloadInterval = parseInt(process.env.RELOAD_MILLISECOND || '');
        setInterval(syncCount, reloadInterval);

        // システム定義/カスタマイズ項目取得
        Promise.all([
          appStore.loadConfig(),
          appStore.loadMenu(),
          userStore.loadUser()
          // countNotifications()
        ]).then((r) => {
          if (!r[0]) {
            // ctx.$sentry.captureMessage('設定ファイルが読み込めませんでした');
            // ctx.error({
            //   statusCode: 401,
            //   message: '設定ファイルが読み込めませんでした'
            // });
            return;
          }

          // せとうちDEMO(STBA)とココペリDEMO(XBA)にnoindex設定
          if (appStore.appConfig.bank_code === '9980' || appStore.appConfig.bank_code === '9998') {
            meta.value = [
              {
                hid: 'robots',
                name: 'robots',
                content: 'noindex'
              }
            ];
          }

          gaId.value = appStore.appConfig.ga_id;
          link.value.push(
            {
              rel: 'icon',
              type: 'image/x-icon',
              href: `${self.$nuxt.context.$config.assetUrl}/customize/${appStore.systemPrefix}/favicon.ico`
            },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: `${self.$nuxt.context.$config.assetUrl}/customize/${appStore.systemPrefix}/apple-touch-icon-180x180.png`
            }
          );

          // yappliのsetRegisteredId情報設定
          if (isNeedYappli()) {
            setYappliInfo(userStore.loginUser.user_unique_code);
          }

          // gtm headerのスクリプト設定
          script.value = getGoogleTagMangerHeaderSetting(gaId.value, appStore.appConfig.gtm_id);

          // gtm bodyの設定
          gtm_body.value = getGoogleTagMangerBodySetting();

          // gtm bodyの設定
          gtm_body2.value = getGoogleTagMangerBodySetting2(appStore.appConfig.gtm_id);

          masterStore.loadMaster().then(() => {
            // Pendo headerのスクリプト設定
            const pendoHeader = getAdminPendoHeaderSetting();
            script.value = [...script.value, ...pendoHeader];

            initialized.value = true;
          });
        });
      });
    const syncCount = async(): Promise<void> => {
      await setunapprovedChatCount();
      await setNoreplyChatCount();
      await setClosedInquiryCount();
    };

    onMounted(() => {
      mounted.value = true;
    });

    const manualData = ref({
      title: '',
      body: ''
    });

    const showManualModal = async (): Promise<void> => {
      if (manualData.value.title && manualData.value.body) {
        isShowManualModal.value = true;
        return;
      }
      try {
        const res = await getManualForBankAdmin() as { result: { title: string, body: string } } | null;
        if (!res) {
          addToastMessage('データ取得に失敗しました', 'error');
          return;
        }
        manualData.value.title = res.result.title;
        manualData.value.body = res.result.body;
        isShowManualModal.value = true;
      } catch (e) {
        addToastMessage('データ取得に失敗しました', 'error');
      }
    };

    return {
      appStore,
      userStore,
      isMobile,
      showMenu,
      initialized,
      isAdmin,
      overlay,
      toastMessages,
      unapprovedChatCount,
      noreplyChatCount,
      closedInquiryCount,
      gtm_body,
      gtm_body2,
      mounted,
      yappliRegisteredId,
      ajustment,
      mainAjustment,
      testBar,
      isShowManualModal,
      showManualModal,
      manualData
    };
  },

  head: {}
});
