
















































import {
  defineComponent,
  ref,
  useMeta,
  useRoute,
  watch,
  // useContext,
  onUnmounted,
  useRouter,
  reactive,
  onMounted,
  watchEffect
} from '@nuxtjs/composition-api';
import * as Sentry from '@sentry/browser';
import Footer from '@/components/layout/footer.vue';
import { provideStore, useStore } from '@/compositions/store';
import { appStore, masterStore, userStore } from '@/store';
import { Search } from '@/compositions/base-search';
import { getGoogleTagMangerHeaderSetting, getGoogleTagMangerBodySetting, getGoogleTagMangerBodySetting2, getPendoHeaderSetting, isNeedYappli, testBar } from '@/utils';

export default defineComponent({
  components: {
    Footer
  },
  setup() {
    provideStore();

    const { title, meta, link, script } = useMeta();

    const {
      overlay,
      toastMessages,
      setFooter,
      initBuiltinChatRooms,
      callProcessEvent,
      eiEventCode,
      eiData,
      yappliRegisteredId,
      setYappliInfo
    } = useStore();
    setFooter(false);

    const {
      loadDataWoState
    } = Search();

    const registeredId = ref<boolean>(false);
    const mounted = ref<boolean>(false);
    const dialog = ref<boolean>(false);
    const initialized = ref<boolean>(false);
    const gaId = ref<string>('');
    // const ctx = useContext();
    const route = useRoute();
    let timer:any = null;

    const state = reactive<any>({
      eventSubscribe: {},
      eventRequester: false
    });

    const router = useRouter();
    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (appStore.appConfig && appStore.appConfig.ga_id && typeof gtag === 'function') {
          gaId.value = appStore.appConfig.ga_id;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }
      }
    );

    watchEffect(() => {
      if (!registeredId.value && yappliRegisteredId.value && mounted.value) {
        Yappli.sendRegisteredId(userStore.loginUser.user_unique_code);
        registeredId.value = true;
      }
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    const gtm_body = ref<string>('');
    const gtm_body2 = ref<string>('');
    // システム定義/カスタマイズ項目取得
    Promise.all([
      appStore.loadConfig(),
      userStore.loadUser(),
      initBuiltinChatRooms()
    ]).then(
      (r) => {
        if (!r[0]) {
          // ctx.$sentry.captureMessage('設定ファイルが読み込めませんでした');
          // ctx.error({
          //   statusCode: 401,
          //   message: '設定ファイルが読み込めませんでした'
          // });
          return;
        }
        const needRedirect = ():string|null => {
          // クレカ登録がない場合、クレカ登録画面を強制表示する
          if (appStore.appConfig.check_payment_method_filled && !appStore.appConfig.is_payment_method_filled) {
            return '/mypage/payment/card/create';
          }
          return null;
        };

        const redirectTo = needRedirect();
        // routerを利用していない画面遷移対応
        if (redirectTo && !location.pathname.startsWith('/mypage/payment')) {
          location.href = redirectTo;
          return;
        }
        router.beforeResolve((to, _, next) => {
          const redirectTo = needRedirect();
          if (redirectTo && to.path !== redirectTo) {
            next(redirectTo);
            return;
          }
          next();
        });

        // yappliのsetRegisteredId情報設定
        if (isNeedYappli()) {
          setYappliInfo(userStore.loginUser.user_unique_code);
        }

        // せとうちDEMO(STBA)とココペリDEMO(XBA)にnoindex設定
        if (appStore.appConfig.bank_code === '9980' || appStore.appConfig.bank_code === '9998') {
          meta.value = [
            {
              hid: 'robots',
              name: 'robots',
              content: 'noindex'
            }
          ];
        }

        gaId.value = appStore.appConfig.ga_id;
        title.value = `${appStore.appConfig.bank.ba_name} (${appStore.appConfig.bank.name})`;
        link.value.push(
          {
            rel: 'icon',
            type: 'image/x-icon',
            href: `${self.$nuxt.context.$config.assetUrl}/customize/${appStore.systemPrefix}/favicon.ico`
          },
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: `${self.$nuxt.context.$config.assetUrl}/customize/${appStore.systemPrefix}/apple-touch-icon-180x180.png`
          }
        );

        // gtm headerのスクリプト設定
        script.value = getGoogleTagMangerHeaderSetting(gaId.value, appStore.appConfig.gtm_id);

        // gtm bodyの設定
        gtm_body.value = getGoogleTagMangerBodySetting();

        // gtm bodyの設定
        gtm_body2.value = getGoogleTagMangerBodySetting2(appStore.appConfig.gtm_id);

        masterStore.loadMaster().then(() => {
          // Pendo headerのスクリプト設定
          const pendoHeader = getPendoHeaderSetting();
          script.value = [...script.value, ...pendoHeader];
          initialized.value = true;
        });
        getEventSubscribe();
        captureSentry();
      }
    );

    const getEventSubscribe = ():void => {
      loadDataWoState(
        process.env.CHAT_URL + '/api/v1/event/subscribe'
      ).then((data: any) => {
        state.eventSubscribe = data.event_subscribe;
        const reloadInterval = parseInt(process.env.CHAT_RELOAD_MILLISECOND || '');
        if (!isNaN(reloadInterval)) {
          timer = setInterval(getEvents, reloadInterval);
        }
      });
    };

    const getEvents = ():void => {
      // ガード節
      if (!state.eventSubscribe) {
        return;
      }

      if (!Object.keys(state.eventSubscribe).length) {
        return;
      }

      if (!state.eventSubscribe.es_unique_code) {
        return;
      }

      if (state.eventRequester) {
        return;
      }

      state.eventRequester = true;

      loadDataWoState(
        process.env.CHAT_URL + '/api/v1/event/list', {
          es_unique_code: state.eventSubscribe.es_unique_code
        },
        'get')
        .then((data: any) => {
          if (data && data.list && data.list.length > 0) {
            data.list.forEach((item: any) => {
              try {
                eiEventCode.value = item.ei_event_code;
                eiData.value = item.ei_data;
                callProcessEvent.value = true;
              } catch (e) {
                console.error('イベント処理エラー', e);
              }
            });
          }
        })
        .finally(() => {
          state.eventRequester = false;
        });
    };

    /**
     * NOTE:
     * ビジネスチャットフロントリプレースで新環境に切り替えた後も
     * 旧チャット画面にアクセスしたユーザーを検知するために追加しています。
     */
    const captureSentry = (): void => {
      const msg = `
以下のユーザーが旧チャット画面にアクセスしました。
userUC: ${userStore.loginUser.user_unique_code}
bankCode: ${appStore.appConfig.bank_code}
`;
      Sentry.captureMessage(msg, Sentry.Severity.Warning);
    };

    onMounted(() => {
      mounted.value = true;
    });

    return { dialog, initialized, overlay, toastMessages, appStore, gtm_body, gtm_body2, mounted, yappliRegisteredId, testBar };
  },
  head: {}
});
