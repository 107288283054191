/* eslint-disable quote-props */
import { appStore } from '@/store';
import { configMap } from '@/configs/xbaDynamicConfig';

/**
 * storeされている銀行コードに応じた設定を返す
 * mappingに存在しない場合はdefaultで指定した設定を返し、
 * defaultにも存在しない場合はキー自体を返す
 *
 * $xdc('key')で使用可能
 * @/plugins/utils.tsにてグローバル登録済み
 */
export function useXbaDynamicConfig(
  key: string
): string {
  // システムコードを取得
  const currentSystemCode = appStore.appConfig.name;
  const bankConfig = configMap[currentSystemCode];
  const defaultConfig = configMap.default;

  // システムコードに対応する設定を優先し、次にデフォルト設定を確認
  const configValue = bankConfig?.[key] || defaultConfig[key];

  // valueプロパティが存在する場合はそれを返し、存在しない場合はキーを返す
  return configValue || key;
}
