/* eslint-disable quote-props */
import hgba from './hgba/index';
import xba from './xba/index';
import { XBA } from '@/configs';
// コンポーネントマップのタイプ定義
type ConfigMap = {
  [key: string]: Record<string, any>;
  default: Record<string, any>;
};

export const configMap: ConfigMap = {
  [XBA.HGBA.SYSTEM_CODE]: hgba, // 肥後銀行
  default: xba // デフォルト設定
};
