/* eslint-disable */

import { getBamAppConfig } from '@/apis/config';
import { appStore, userStore } from '@/store';
import service from '@/utils/api';

export default async function ({ $auth, redirect, store, app }) {
  if ($auth.loggedIn) {
    if (!store.state.user.loginUser.user_type_group) {
      const config = await getBamAppConfig();
      if (config && config.result && config.result.api_url) {
        appStore.SET_BASE_URL(config.api_url);
      } else {
        appStore.SET_BASE_URL(process.env.API_URL || '');
      }
      service.defaults.baseURL = appStore.baseUrl;

      await userStore.loadUser();
    }

    // 金融機関支店ユーザーのオペレーション制御
    if (
      store.state.user.loginUser.user_type_group === 'bank' &&
      process.env.DEBUG !== 'true'
    ) {
      // IPアドレス制限
      if (
        !store.state.user.isPermittedIpaddress &&
        process.env.DEBUG !== 'true'
      ) {
        app.context.error({
          statusCode: 422,
          message: 'この端末からはアクセスできません。'
        });
        return;
      }

      // 利用時間制限
      if (store.state.user.offhourMessage) {
        app.context.error({
          statusCode: 422,
          message: store.state.user.offhourMessage.replace(/\n/g, '<br>')
        });
        return;
      }
    }

    // 企業ユーザーが管理画面にアクセスした場合のリダイレクト処理
    if (
      app.context.from.path.startsWith('/admin') &&
      store.state.user.loginUser.user_type_group === 'company'
    ) {
      window.location.replace('/mypage/dashboard');
    }

    // 金融機関ユーザーが企業マイページにアクセスした場合のリダイレクト処理
    if (
      app.context.from.path.startsWith('/mypage') &&
      store.state.user.loginUser.user_type_group !== 'company'
    ) {
      window.location.replace('/admin/dashboard');
    }
  } else {
    sessionStorage.clear();
    location.href = `/login?r=${app.context.route.fullPath}`;
  }
}

