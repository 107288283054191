import request from '@/utils/api';

/**
 * リクエスト実行
 *
 * @template T
 * @param {string} url リクエストURL
 * @param {string} method method
 * @param {T} paramaters リクエストパラメータ
 * @return {*} レスポンス
 */
export const requestApi = <T extends {}>(
  url: string,
  paramaters: T,
  method: string,
  options?: {}
): any => {
  options = options || {};
  switch (method) {
    case 'post':
      return postData(url, paramaters, options);
    case 'get':
      return getData(url, paramaters, options);
    case 'put':
      return putData(url, paramaters, options);
    case 'delete':
      return deleteData(url, paramaters, options);
  }
};

/**
 * GETリクエスト実行
 *
 * @template T
 * @param {string} url リクエストURL
 * @param {T} paramaters リクエストパラメータ
 * @param {Object} options headers等その他追加設定
 * @return {*} レスポンス
 */
export const getData = <T extends {}>(
  url: string,
  paramaters: T,
  options?: {}
): any => {
  return request({
    url,
    method: 'get',
    params: paramaters,
    ...options
  });
};

/**
 * POSTリクエスト実行
 *
 * @template T
 * @param {string} url リクエストURL
 * @param {T} paramaters リクエストパラメータ
 * @return {*} レスポンス
 */
export const postData = <T extends {}>(
  url: string,
  paramaters: T,
  options: {}
): any => {
  return request({
    url,
    method: 'post',
    data: paramaters,
    ...options
  });
};

/**
 * DELETEリクエスト実行
 *
 * @template T
 * @param {string} url リクエストURL
 * @param {T} paramaters リクエストパラメータ
 * @return {*} レスポンス
 */
export const deleteData = <T extends {}>(
  url: string,
  paramaters: T,
  options: {}
): any => {
  return request({
    url,
    method: 'delete',
    data: paramaters,
    ...options
  });
};

/**
 * PUTリクエスト実行
 *
 * @template T
 * @param {string} url リクエストURL
 * @param {T} paramaters リクエストパラメータ
 * @return {*} レスポンス
 */
export const putData = <T extends {}>(
  url: string,
  paramaters: T,
  options: {}
): any => {
  return request({
    url,
    method: 'put',
    data: paramaters,
    ...options
  });
};

// 未読の配信数(ご案内/お知らせメニュー用)
export const getUnreadCmiCount = (): any => {
  return request({
    url: '/api/v1/cmi/search',
    method: 'post',
    data: { only_unread: 1 }
  });
};

// 金融機関連絡チャット bachat_rooms_unread_count '/api/v1/ba/chat/rooms'
export const getBachatRooms = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BAM_BASE_URL + '/api/v1/ba/chat/rooms',
    method: 'get'
  });
};

// ビジネスチャット chat_rooms_unread_count  '/api/v1/chat/room/list'
export const getChatRooms = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.CHAT_URL + '/api/v1/chat/room/list',
    method: 'get'
  });
};

// 入札種別
export const getBiddingCategories = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/xba/bidding/categories',
    method: 'get'
  });
};

// チャット定型文取得
export const getUserCfp = (): any =>
  request({
    url: '/api/v1/user/cfp',
    method: 'get'
  });

export const getChatCpr = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BAM_BASE_URL + '/api/v1/chat/cpr/search',
    method: 'get'
  });
};

export const getCmc = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/cmc/search?only_unapproved=1&only_can_approve=1',
    method: 'get'
  });
};

export const getNoreplyChatCount = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BAM_BASE_URL + '/api/v1/ba/chat/noreply_summary',
    method: 'get'
  });
};

// お問い合わせ
export const getClosedInquiryCount = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: '/api/v1/inquiry/closed_summary',
    method: 'get'
  });
};

// 安否確認
export const getOpenSC = (): any => {
  return request({
    url: '/api/v1/sc/open',
    method: 'get'
  });
};

export const postInitBuiltinChatRooms = (): any => {
  return postData(
    '/api/v1/chat/init_builtin_chat_rooms',
    {},
    {}
  );
};

// zendeskのトークン取得
export const getZendeskToken = (): any => {
  return postData(
    process.env.BAM_BASE_URL + '/api/v1/zendesk/token',
    {},
    {}
  );
};

// 自社への商談依頼を取得
export const getMatchingReceivedHistory = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BAM_BASE_URL + '/api/v1/matching/offers/to_my_companies',
    method: 'get'
  });
};

// 金融機関ユーザー用のマニュアルを取得する
export const getManualForBankAdmin = (): any => {
  if (
    !localStorage.getItem('auth._token.bamidp') ||
    localStorage.getItem('auth._token.bamidp') === 'false'
  ) {
    return null;
  }
  return request({
    url: process.env.BA_ADMIN_URL + '/api/manual',
    method: 'get'
  });
};

export const fetchPartnerCompanyUserLimitApi = async (): Promise<number | void> => {
  const response: any = await request({
    url: '/api/v1/setting/partner_company_user_limit',
    method: 'get'
  });
  return response.result;
};
